import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { About } from '../components/About'
import { Company } from '../components/Company'
import './frame.css'
import { Footer } from './Footer'
import { WelcomeTo } from './WelcomeTo'
import { Products } from './Products'
import { Services } from './Services'
import responseBody from './payload'
import ErrorPage from './ErrorPage'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Careers from './Careers'
import toast, { Toaster } from 'react-hot-toast'

// const router = createBrowserRouter([
//     {
//         path: '/',
//         element: <Root />,
//         errorElement: <ErrorPage />,
//     },
//     {
//         path: 'contacts/:contactId',
//         element: <Contact />,
//     },
// ])
export const Main = () => {
    const [bodyJson, setBodyJson] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    useEffect(async () => {
        setIsLoading(true)

        const respBody = await fetch(
            'https://devwebsite.buildbooster.com/tenant/2'
        )
            .then((resp) => resp.json())
            .catch((err) =>
                toast.error(
                    'Something wend wrong while fetching data: ' +
                        JSON.stringify(err)
                )
            )
        setTimeout(() => {
            setBodyJson(responseBody)
        }, 1000)
    }, [])
    console.log({ bodyJson })
    return (
        <>
            <Toaster position="bottom-right" reverseOrder={false} />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="services" element={<Services />} />
                        <Route
                            path="products"
                            element={
                                <Products
                                    {...(bodyJson?.sections?.products || {})}
                                />
                            }
                        />
                        <Route path="careers" element={<Careers />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

const Layout = () => (
    <div className="frame">
        <Header className="component-4" property1="sign-in" />
        <Outlet />
        <Footer />
    </div>
)
const Home = () => (
    <div className="div-2">
        <Company className="component-3" property1="frame-986641" />
        <About
            className="component-2"
            frameClassName="component-instance"
            property1="bullet"
        />
        <WelcomeTo />
        <Products />
        <Services />
    </div>
)
