import React from 'react'
import { Label } from 'reactstrap'
import './footer.css'
import { Contact } from './Contact'
import { BookACall } from './BookACall'
export function Footer() {
    return (
        <>
            <div className="book-a-call">
                <BookACall />
            </div>
            <div className="bb-footer" id="welcome">
                <Contact />
            </div>
        </>
    )
}
