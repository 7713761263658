import React from 'react'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'

export function ContactForm() {
    return (
        <div className="m-5">
            <h2 className="get-a-quote-and-make my-5">
                Get a quote and make your dreams come true
            </h2>
            <Form>
                <FormGroup>
                    <Input
                        type="text"
                        rows="5"
                        id="exampleMessage"
                        name="message"
                        placeholder="Full name"
                    />
                </FormGroup>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Input
                                id="exampleEmail"
                                name="email"
                                placeholder="Email"
                                type="email"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input
                                id="examplePhone"
                                name="Phone"
                                placeholder="Phone"
                                type="Phone"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Input
                        type="textarea"
                        rows="5"
                        id="exampleMessage"
                        name="message"
                        placeholder="1234 Main St"
                    />
                </FormGroup>
                <Button size="lg" color="primary">
                    Get in touch
                </Button>
            </Form>
        </div>
    )
}
