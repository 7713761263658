import React from 'react'

export function LandingImageWithHeader({
    image = './img/careers.png',
    header,
}) {
    return (
        <div
            className="landing-page-image rounded w-100"
            style={{
                backgroundImage: "url('" + image + "')",
                height: '350',
                textAlign: 'center',
                color: 'white',
                position: 'relative',
            }}
            alt="careers"
        >
            <h1 className="landing-page-header position-absolute ">{header}</h1>
        </div>
    )
}
