import React from 'react'

export function WelcomeTo({
    welcomeImage = '/img/image-7.png',
    welcomeMessage = 'WELCOME TO ANDERSON CONSTRUCTIONS',
    welcomeDescription = 'Your trusted partner in quality construction materials. With decades of experience, we offer a wide range of products, expert guidance, and exceptional service. We are dedicated to helping you build your dream home, one reliable material at a time.',
}) {
    return (
        <section className="overlap-2">
            <img className="image-2" alt="Image" src={welcomeImage} />
            <div className="frame-wrapper-4">
                <div className="div-15">
                    <div className="div-11">
                        <div className="welcome-to-anderson">
                            {welcomeMessage}
                        </div>
                        <p className="your-trusted-partner">
                            {welcomeDescription}
                        </p>
                    </div>
                    <button className="div-wrapper-7 text-wrapper-19">
                        Know more
                        {/* <div className="text-wrapper-19">Know more</div> */}
                    </button>
                </div>
            </div>
        </section>
    )
}
