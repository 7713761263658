/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types'
import React from 'react'
import './company.css'

export const Company = ({
    header = 'Products & Services for Professional Builders',
}) => {
    return (
        <div className={`company-section-wrapper`}>
            <div
                className="overlap-group"
                style={{
                    backgroundImage: "url('./img/image-9-1.png')",
                }}
            >
                <div className={`blog-card blog-card-bg`}>
                    <div className="text-box-outer">
                        <p className="products-services">{header}</p>
                        <button className="company-button">
                            <div className="company-button-text">
                                Get a Quote
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Company.propTypes = {}
