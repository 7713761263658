import React from 'react'
import { Col, Row } from 'reactstrap'

export function ContactLinks({
    footerLinks = [
        {
            name: 'Link 1',
            url: '/link1',
        },
        {
            name: 'Link 2',
            url: '/link2',
        },
        {
            name: 'Link 3',
            url: '/link3',
        },
        {
            name: 'Link4',
            url: '/link4',
        },
        {
            name: 'Link5',
            url: '/link5',
        },
        {
            name: 'Link6',
            url: '/link6',
        },
        {
            name: 'Link7',
            url: '/link7',
        },
    ],
}) {
    return (
        <div className="contact-links">
            <div className="">
                <img
                    className="mask-group"
                    alt="Mask group"
                    src="/img/mask-group-3.png"
                />
            </div>
            <div>
                <h4 className="">
                    Efficiently organize and manage your construction project
                    from start to finish
                </h4>
            </div>
            <Row className="form footer-links my-4">
                {footerLinks.map(({ url, name }) => (
                    <Col className="mt-4" md="6" key={name}>
                        <a
                            className="text-decoration-none"
                            href={url}
                            target="_blank"
                        >
                            {name}
                        </a>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
