/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LocationIcon = ({ color = "black", className }) => {
  return (
    <svg
      className={`location-24-outline-2 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 2.5C9.87827 2.5 7.84344 3.34285 6.34315 4.84315C4.84285 6.34344 4 8.37827 4 10.5C4 15.9 11.05 22 11.35 22.26C11.5311 22.4149 11.7616 22.5001 12 22.5001C12.2384 22.5001 12.4689 22.4149 12.65 22.26C13 22 20 15.9 20 10.5C20 8.37827 19.1571 6.34344 17.6569 4.84315C16.1566 3.34285 14.1217 2.5 12 2.5ZM12 20.15C9.87 18.15 6 13.84 6 10.5C6 8.9087 6.63214 7.38258 7.75736 6.25736C8.88258 5.13214 10.4087 4.5 12 4.5C13.5913 4.5 15.1174 5.13214 16.2426 6.25736C17.3679 7.38258 18 8.9087 18 10.5C18 13.84 14.13 18.16 12 20.15ZM12 6.5C11.2089 6.5 10.4355 6.7346 9.77772 7.17412C9.11992 7.61365 8.60723 8.23836 8.30448 8.96927C8.00173 9.70017 7.92252 10.5044 8.07686 11.2804C8.2312 12.0563 8.61216 12.769 9.17157 13.3284C9.73098 13.8878 10.4437 14.2688 11.2196 14.4231C11.9956 14.5775 12.7998 14.4983 13.5307 14.1955C14.2616 13.8928 14.8864 13.3801 15.3259 12.7223C15.7654 12.0645 16 11.2911 16 10.5C16 9.43913 15.5786 8.42172 14.8284 7.67157C14.0783 6.92143 13.0609 6.5 12 6.5ZM12 12.5C11.6044 12.5 11.2178 12.3827 10.8889 12.1629C10.56 11.9432 10.3036 11.6308 10.1522 11.2654C10.0009 10.8999 9.96126 10.4978 10.0384 10.1098C10.1156 9.72186 10.3061 9.36549 10.5858 9.08579C10.8655 8.80608 11.2219 8.6156 11.6098 8.53843C11.9978 8.46126 12.3999 8.50087 12.7654 8.65224C13.1308 8.80362 13.4432 9.05996 13.6629 9.38886C13.8827 9.71776 14 10.1044 14 10.5C14 11.0304 13.7893 11.5391 13.4142 11.9142C13.0391 12.2893 12.5304 12.5 12 12.5Z"
        fill={color}
      />
    </svg>
  );
};

LocationIcon.propTypes = {
  color: PropTypes.string,
};
