{
    "address": "1211, Church Street Road, Chicago 560120",
    "phoneNumber": "+01 9805269134",
    "links": ["COMPANY", "PRODUCTS", "SERVICES", "CAREERS"],
    "sections": {
        "company": {
            "description": "For over 125 years builders and professional contractors have relied upon us for solid advice, high quality building materials and supplies. Our roots can be traced back to 1897 when Albert Lyman started a small lumberyard known as Lyman Lumber in Excelsior, Minnesota. Since then that small lumberyard has grown into several companies, but we are still a privately held corporation whose primary business is providing goods and services to the residential construction industry.",
            "companyTagLine": "YOUR TRUSTED BUILDING MATERIALS SUPPLIER",
            "features": [
                "Extensive Product Range",
                "Competitive Pricing",
                "Trusted Brand",
                "Bulk Discounts"
            ]
        },
        "products": {
            "header": "BUILDING & REMODELING PRODUCTS",
            "products": [
                {
                    "name": "CABINETRY",
                    "description": "Our design specialists have an extensive selection to meet the demands of any project",
                    "image": "./img/frame-83.png"
                },
                {
                    "name": "DECKING",
                    "description": " We feature quality decking, railing, and porch materials available in -stock and ready to deliver.",
                    "image": "./img/frame-83-1.png"
                },
                {
                    "name": "DOOR HARDWARE",
                    "description": "  Wide array of architectural hardware includes locks, hinges, doorknobs, and levers.",
                    "image": "./img/frame-83-2.png"
                },
                {
                    "name": "DOORS",
                    "description": "  Exterior and entry doors in a wide array of colors, styles, and brands.",
                    "image": "./img/frame-83-3.png"
                }
            ]
        }
    }
}
