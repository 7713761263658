/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types'
import React from 'react'
import { PropertyYearsWrapper } from '../PropertyYearsWrapper'
import './about.css'
import { Feature } from './Feature'

export const About = ({
    bullet = 'bullet',
    description = 'For over 125 years builders and professional contractors have relied upon us for solid advice, high quality building materials and supplies. Our roots can be traced back to 1897 when Albert Lyman started a small lumberyard known as Lyman Lumber in Excelsior, Minnesota. Since then that small lumberyard has grown into several companies, but we are still a privately held corporation whose primary business is providing goods and services to the residential construction industry.',
    companyTagLine = 'YOUR TRUSTED BUILDING MATERIALS SUPPLIER',
    features = [
        'Extensive Product Range',
        'Competitive Pricing',
        'Trusted Brand',
        'Bulk Discounts',
    ],
}) => {
    return (
        <section
            className={`property-check-wrapper component-3  mt-5`}
            id="company"
        >
            <div className={`overlap-group-wrapper bullet component-instance`}>
                <div className="overlap-group-2">
                    <img
                        className="image"
                        alt="Image"
                        src="/img/image-10-1.png"
                    />
                    <PropertyYearsWrapper
                        className="component-1"
                        property1="years"
                    />
                </div>
            </div>
            <div className=" frame-15">
                <div className="frame-16">
                    <p className="p">{companyTagLine}</p>
                    <p className="text-wrapper-8">{description}</p>
                </div>
                <div className="features-wrapper">
                    {features.map((feature) => (
                        <Feature key={feature} {...{ feature, bullet }} />
                    ))}
                    {/* <div className={`frame-20 property-1-2-${bullet}`}>
                        <div className="frame-21">
                            {bullet === 'check-marks' && (
                                <ApproveTick24Outline3 className="approve-tick" />
                            )}
                        </div>
                        <div className="text-wrapper-10">
                            Competitive Pricing
                        </div>
                    </div>
                    <div className={`frame-22 property-1-5-${bullet}`}>
                        <div className="frame-23">
                            {bullet === 'check-marks' && (
                                <ApproveTick24Outline3 className="approve-tick" />
                            )}
                        </div>
                        <div className="text-wrapper-11">
                          Trusted Brand
                          </div>
                    </div>
                    <div className={`frame-24 property-1-8-${bullet}`}>
                        <div className="frame-25">
                            {bullet === 'check-marks' && (
                                <ApproveTick24Outline3 className="approve-tick" />
                            )}
                        </div>
                        <div className="text-wrapper-12">
                          Bulk Discounts
                          </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

About.propTypes = {
    property1: PropTypes.oneOf(['bullet', 'check-marks']),
}
