import React from 'react'

export function OpenPositions({ positions, onSelect }) {
    return (
        <div className="mw-50">
            <h1 className="my-5">OPEN POSITIONS</h1>
            {positions.map(({ title, location, ...rest }) => (
                <div key={title}>
                    <div className="d-flex justify-content-between ">
                        <div>
                            <h2>{title}</h2>
                            <p>{location}</p>
                        </div>

                        <div>
                            <svg
                                className="pointer"
                                onClick={() =>
                                    onSelect({ title, location, ...rest })
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                            >
                                <rect
                                    width="48"
                                    height="48"
                                    rx="24"
                                    fill="#E7E6FF"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M26.8802 16.0001C26.6812 15.9977 26.4859 16.0526 26.3194 16.1579C26.1529 16.2631 26.0228 16.4138 25.9457 16.5908C25.8686 16.7677 25.8481 16.9628 25.8867 17.151C25.9253 17.3393 26.0213 17.5122 26.1624 17.6475L31.6149 23.0351H14.0143C13.8818 23.0333 13.7502 23.0569 13.6272 23.1046C13.5043 23.1522 13.3923 23.2229 13.298 23.3126C13.2036 23.4023 13.1286 23.5092 13.0775 23.627C13.0263 23.7449 13 23.8714 13 23.9991C13 24.1269 13.0263 24.2534 13.0775 24.3712C13.1286 24.4891 13.2036 24.596 13.298 24.6857C13.3923 24.7753 13.5043 24.8461 13.6272 24.8937C13.7502 24.9413 13.8818 24.9649 14.0143 24.9631H31.6149L26.1624 30.3517C26.0683 30.4418 25.9936 30.5491 25.9429 30.6673C25.8921 30.7855 25.8663 30.9122 25.8669 31.0401C25.8675 31.168 25.8945 31.2946 25.9463 31.4123C25.9981 31.5301 26.0737 31.6367 26.1687 31.726C26.2637 31.8154 26.3761 31.8856 26.4996 31.9326C26.623 31.9796 26.7549 32.0024 26.8875 31.9998C27.0202 31.9972 27.151 31.9691 27.2723 31.9173C27.3936 31.8655 27.503 31.7909 27.5942 31.6979L34.6131 24.7607C34.7335 24.6706 34.831 24.5551 34.8981 24.4231C34.9651 24.2911 35 24.1461 35 23.9991C35 23.8522 34.9651 23.7071 34.8981 23.5751C34.831 23.4431 34.7335 23.3277 34.6131 23.2375L34.6111 23.2357L27.5942 16.3013C27.5021 16.2076 27.3915 16.1327 27.2689 16.0809C27.1463 16.0292 27.0141 16.0017 26.8802 16.0001Z"
                                    fill="#090088"
                                />
                            </svg>
                        </div>
                    </div>
                    <hr></hr>
                </div>
            ))}
        </div>
    )
}
