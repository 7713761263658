import React from 'react'

export function ProductCard({ image, header, description }) {
    return (
        <div className="card" style={{}}>
            <img
                src={image}
                className="card-img-top"
                alt={header || 'header'}
            />
            <div className="card-body">
                <h5 className="fw-bold">{header}</h5>
                <p className="card-text">{description}</p>
            </div>
        </div>
    )
}
