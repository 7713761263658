import React, { useState } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Input,
    InputGroup,
    InputGroupText,
} from 'reactstrap'
import { IconlyLightSearch } from '../../icons/IconlyLightSearch'

export function HeaderNav({
    links = ['COMPANY', 'PRODUCTS', 'SERVICES', 'CAREERS'],
    ...args
}) {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    return (
        <Navbar {...args} expand="md">
            <NavbarBrand href="/">
                <img
                    // onClick={() => useNavigate('/')}
                    className="mask-group"
                    alt="Mask group"
                    src={'/img/mask-group.png'}
                />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="m-auto fw-bold" navbar>
                    {links.map((link) => (
                        <NavItem key={link}>
                            <NavLink
                                href={`/${link.toLowerCase()}`}
                                active={window.location.href.includes(
                                    link.toLowerCase()
                                )}
                            >
                                {link}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <NavbarText>
                    <form className="d-flex">
                        <InputGroup size="lg">
                            <InputGroupText>
                                <IconlyLightSearch
                                    className="instance-node"
                                    color="#677489"
                                />
                            </InputGroupText>
                            <Input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                            />
                        </InputGroup>

                        <button
                            className="btn btn-outline-primary"
                            type="submit"
                        >
                            SignIn
                        </button>
                    </form>
                </NavbarText>
            </Collapse>
        </Navbar>
    )
}
