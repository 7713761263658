import React, { useState } from 'react'

import { OpenPositions } from './OpenPositions'
import { Col, Row } from 'reactstrap'
import { PositionDescription } from './PositionDescription'
import { LandingImageWithHeader } from './LandingImageWithHeader'

export default function Careers({
    image = './img/careers.png',
    positions = [
        {
            description: ` 

## About the Company

[Company Name] is a dynamic and innovative [industry/sector] company dedicated to [briefly describe the company's mission or core values]. We pride ourselves on fostering creativity, pushing boundaries, and delivering top-notch [industry-specific] solutions to our clients. Join our team of passionate professionals who are committed to excellence in everything we do.

## What You Will Be Doing

As a Video Editor at [Company Name], you will play a crucial role in crafting visually stunning and engaging video content that captivates our audience. You will have the opportunity to work on a wide range of exciting projects, from [mention specific types of projects, e.g., promotional videos, documentaries, product demos] to [mention another type of project]. Your responsibilities will include:

## Responsibilities

- Collaborating closely with our creative team to conceptualize and storyboard video projects.
- Editing and assembling raw footage into a polished, cohesive, and visually appealing final product.
- Enhancing video and audio quality using editing software and tools.
- Adding visual effects, graphics, and animations to enhance the overall production quality.
- Managing and organizing video assets, ensuring they are easily accessible for future use.
- Continuously staying updated on industry trends and best practices in video editing.
- Maintaining project timelines and meeting deadlines consistently.
- Incorporating feedback from stakeholders to refine and improve video content.

## Who Can Apply

We are looking for a talented and passionate individual with the following qualifications and attributes:

- Proven experience as a Video Editor or a similar role in the field of [mention relevant industry, e.g., film, advertising, digital media].
- Proficiency in video editing software such as Adobe Premiere Pro, Final Cut Pro, or similar tools.
- A strong portfolio showcasing your video editing skills and creativity.
- Solid understanding of video production processes and techniques.
- Knowledge of color grading, motion graphics, and visual effects is a plus.
- Excellent attention to detail and a keen eye for visual storytelling.
- Strong communication and teamwork skills.
- Ability to work independently and manage multiple projects simultaneously.
- [Optional: Bachelor's degree in [relevant field] or equivalent work experience.]

If you are a talented Video Editor who is passionate about creating compelling video content`,
            title: 'Video Editor',
            location: 'Austin',
        },
        { description: '', title: 'Project manager', location: 'Austin' },
        { description: '', title: 'Sales man', location: 'Austin' },
        { description: '', title: 'Interiro designer', location: 'Austin' },
        { description: '', title: 'Planner', location: 'Austin' },
    ],
}) {
    const [selectedPosition, setselectedPosition] = useState()

    if (selectedPosition) {
        return (
            <PositionDescription
                position={selectedPosition}
                toggle={() => setselectedPosition()}
            />
        )
    }
    return (
        <div className="careers">
            <LandingImageWithHeader image={image} header="Careers" />{' '}
            <Row>
                <Col />
                <Col md="8">
                    <OpenPositions
                        positions={positions}
                        onSelect={setselectedPosition}
                    ></OpenPositions>
                </Col>
                <Col />
            </Row>
        </div>
    )
}

export function BackArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="20"
            viewBox="0 0 27 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.96515 19.9999C10.2095 20.0029 10.4491 19.9342 10.6534 19.8027C10.8578 19.6711 11.0174 19.4827 11.1121 19.2615C11.2067 19.0404 11.2319 18.7965 11.1845 18.5612C11.1371 18.3259 11.0193 18.1098 10.8461 17.9406L4.15446 11.2061L25.7552 11.2061C25.9178 11.2083 26.0793 11.1788 26.2302 11.1193C26.3811 11.0597 26.5185 10.9713 26.6343 10.8592C26.7502 10.7471 26.8421 10.6135 26.9049 10.4662C26.9677 10.3189 27 10.1608 27 10.0011C27 9.84139 26.9677 9.68329 26.9049 9.53598C26.8421 9.38866 26.7502 9.25506 26.6343 9.14294C26.5185 9.03082 26.3811 8.94242 26.2302 8.88288C26.0793 8.82334 25.9178 8.79384 25.7552 8.79609L4.15446 8.79609L10.8461 2.06039C10.9617 1.94774 11.0533 1.81365 11.1156 1.66591C11.1778 1.51816 11.2095 1.35973 11.2088 1.19984C11.2081 1.03995 11.175 0.881802 11.1114 0.734601C11.0478 0.587399 10.955 0.454091 10.8385 0.342444C10.7219 0.230797 10.5838 0.143046 10.4324 0.0842976C10.2809 0.0255494 10.119 -0.00302272 9.95621 0.000254095C9.79339 0.003529 9.63286 0.0385841 9.48397 0.103375C9.33508 0.168166 9.20082 0.261399 9.08899 0.37764L0.474833 9.04909C0.32701 9.16174 0.207379 9.30608 0.125081 9.47108C0.0427827 9.63608 -1.01715e-06 9.81736 -1.03322e-06 10.0011C-1.04928e-06 10.1848 0.0427827 10.3661 0.125081 10.5311C0.207379 10.6961 0.32701 10.8404 0.474833 10.9531L0.477225 10.9554L9.08899 19.6233C9.20193 19.7405 9.33768 19.8342 9.48818 19.8989C9.63868 19.9635 9.80087 19.9979 9.96515 19.9999Z"
                fill="#090088"
            />
        </svg>
    )
}
