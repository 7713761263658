import React from 'react'
import { Col, Row } from 'reactstrap'
import { ContactForm } from './ContactForm'
import { ContactLinks } from './ContactLinks'

export const Contact = () => (
    <Row className="mt-5 contact">
        <Col md="6">
            <ContactLinks />
        </Col>
        <Col md="6" className="bg-black rounded my-5">
            <ContactForm />
        </Col>
    </Row>
)
