/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react'
import './prop-years.css'

export const PropertyYearsWrapper = ({  className }) => {
    return (
        <div className={`property-years-wrapper component-1`}>
            <div className={`frame-14 years`}>
                <div className="element">{<>10+</>}</div>
                <div className="years-experience">{<>Years experience</>}</div>
            </div>
        </div>
    )
}

