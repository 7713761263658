import React from 'react'
// import { LeftArrow24Outline3 } from '../icons/LeftArrow24Outline3'
// import { LeftArrow24Outline4 } from '../icons/LeftArrow24Outline4'
import { RightArrow24Outline2 } from '../icons/RightArrow24Outline2'
import { LandingImageWithHeader } from './Careers/LandingImageWithHeader'
// import { ProductCard } from './ProductCard'
import { ServicesCard } from './ServicesCard'

export function Services({
    image,
    services = [
        {
            image: './img/frame-83-4.png',
            name: 'WALL & FLOOR PANELS',
            description:
                'Our crew is trained to install all types of wall and floor panels.',
        },
        {
            image: './img/frame-83-5.png',
            name: 'WINDOW & DOOR',
            description:
                'Our in house crews are trained to install all types of windows and doors.',
        },
        {
            image: './img/frame-83-6.png',
            name: 'TRIM CARPENTRY LABOR',
            description:
                'We have experienced teams for finishing carpentry and cabinetry installations',
        },
        {
            image: './img/frame-83-7.png',
            name: 'FRAMING CARPENTRY',
            description:
                'We provide professional, skilled, experienced and well-equipped framing carpenters.',
        },
    ],
}) {
    return (
        <section className="services" id="services">
            {/* <LandingImageWithHeader image={image} header="Services" /> */}

            <div className="title-to-center">
                <div className="text-wrapper-20">SERVICES</div>
                <div className="rectangle" />
            </div>
            <div className="">
                <div className="services-scroll">
                    <div className="div-22 row">
                        {/* <LeftArrow24Outline3 className="left-arrow" /> */}

                        {services.map(({ image, name, description }) => (
                            <div className="col rounded" key={name + image}>
                                <ServicesCard
                                    image={image}
                                    header={name}
                                    description={description}
                                />
                            </div>
                        ))}

                        {/* <LeftArrow24Outline4 className="left-arrow" /> */}
                    </div>
                </div>
            </div>
            <div className="view-all-wrapper">
                <div className="text-wrapper-24">VIEW ALL</div>
                <RightArrow24Outline2 className="right-arrow" color="#160AC2" />
            </div>
        </section>
    )
}
