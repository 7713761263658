import React from 'react'
import { ApproveTick24Outline3 } from '../../icons/ApproveTick24Outline3'

export function Feature({ feature, bullet }) {
    return (
        <div key={feature} className={`frame-18 property-1-${bullet}`}>
            <div className="frame-19">
                {bullet === 'check-marks' && (
                    <ApproveTick24Outline3 className="approve-tick" />
                )}
            </div>
            <div className="text-wrapper-9">{feature}</div>
        </div>
    )
}
