import React from 'react'
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap'
import { BackArrow } from '.'
import { marked } from 'marked'

export function PositionDescription({ position, toggle }) {
    const {
        title,
        location,
        description = `
    

## About the Company

[Company Name] is a dynamic and innovative [industry/sector] company dedicated to [briefly describe the company's mission or core values]. We pride ourselves on fostering creativity, pushing boundaries, and delivering top-notch [industry-specific] solutions to our clients. Join our team of passionate professionals who are committed to excellence in everything we do.

## What You Will Be Doing

As a Video Editor at [Company Name], you will play a crucial role in crafting visually stunning and engaging video content that captivates our audience. You will have the opportunity to work on a wide range of exciting projects, from [mention specific types of projects, e.g., promotional videos, documentaries, product demos] to [mention another type of project]. Your responsibilities will include:

## Responsibilities

- Collaborating closely with our creative team to conceptualize and storyboard video projects.
- Editing and assembling raw footage into a polished, cohesive, and visually appealing final product.
- Enhancing video and audio quality using editing software and tools.
- Adding visual effects, graphics, and animations to enhance the overall production quality.
- Managing and organizing video assets, ensuring they are easily accessible for future use.
- Continuously staying updated on industry trends and best practices in video editing.
- Maintaining project timelines and meeting deadlines consistently.
- Incorporating feedback from stakeholders to refine and improve video content.

## Who Can Apply

We are looking for a talented and passionate individual with the following qualifications and attributes:

- Proven experience as a Video Editor or a similar role in the field of [mention relevant industry, e.g., film, advertising, digital media].
- Proficiency in video editing software such as Adobe Premiere Pro, Final Cut Pro, or similar tools.
- A strong portfolio showcasing your video editing skills and creativity.
- Solid understanding of video production processes and techniques.
- Knowledge of color grading, motion graphics, and visual effects is a plus.
- Excellent attention to detail and a keen eye for visual storytelling.
- Strong communication and teamwork skills.
- Ability to work independently and manage multiple projects simultaneously.
- [Optional: Bachelor's degree in [relevant field] or equivalent work experience.]

If you are a talented Video Editor who is passionate about creating compelling video content
`,
    } = position
    return (
        <div className="careers m-5 ">
            <div onClick={toggle} className="pointer">
                <BackArrow /> Open Positions
            </div>
            <div className="my-5">
                <div className="d-flex justify-content-between ">
                    <div>
                        <h2>{title}</h2>
                        <p>{location}</p>
                    </div>

                    <div>
                        <Button color="primary">Apply now</Button>
                    </div>
                </div>
                <div>
                    <article
                        dangerouslySetInnerHTML={{
                            __html: marked(description),
                        }}
                    ></article>
                </div>

                <Form>
                    <FormGroup>
                        <Label for="applicant-name">Whats your name?</Label>
                        <Input
                            id="applicant-name"
                            name="email"
                            placeholder="Type your name"
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="applicant-email">
                            Whats your email address?
                        </Label>
                        <Input
                            id="applicant-email"
                            name="email"
                            placeholder="your email address"
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="applicant-mobile">
                            Whats your mobile number?
                        </Label>
                        <Input
                            id="applicant-mobile"
                            name="password"
                            placeholder="Your mobile number"
                            type="password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="current-ctc">Whats your CTC?</Label>
                        <Input
                            id="current-ctc"
                            name="password"
                            placeholder="Your CTC"
                            type="password"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="cv-attachment">Attach your resume</Label>
                        <Input id="cv-attachment" name="file" type="file" />
                        <FormText>Please attach a pdf or doc file.</FormText>
                    </FormGroup>

                    <Button color="primary">Applt</Button>
                </Form>
            </div>
        </div>
    )
}
