import React from 'react'
import { Button, Col, Row } from 'reactstrap'

export function BookACall() {
    return (
        <Row className="container-fluid rounded footer-top mt-5 row bg-white w-75 mx-auto">
            <Col className="m-5">
                <img
                    className=""
                    height={'50'}
                    alt="Frame"
                    src="/img/frame-986655.svg"
                />
                <div className="">
                    <h3 className="">
                        We’re On Call 24/7 to React Promptly to Your Service
                        Needs
                    </h3>
                    <p className="color-secondary my-2">
                        Do not allow a restricted budget to prevent you from
                        obtaining the necessary comfort for your residence or
                        commercial property. We are just one call away.
                    </p>
                </div>
            </Col>
            <Col className="m-5">
                <div className="text-center position-relative">
                    <img src="./img/image-8.png" alt="callnow" />
                    <div
                        className="position-absolute "
                        style={{ left: '50%', marginLeft: -87, bottom: 15 }}
                    >
                        <Button size="lg" color="primary">
                            Book a call
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
