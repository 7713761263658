import React from 'react'
import { LeftArrow24Outline1 } from '../icons/LeftArrow24Outline1'
import { LeftArrow24Outline4 } from '../icons/LeftArrow24Outline4'
import { RightArrow24Outline2 } from '../icons/RightArrow24Outline2'
import './product.css'
import { ProductCard } from './ProductCard'
import { LandingImageWithHeader } from './Careers/LandingImageWithHeader'
export function Products({
    header = 'Loading...',
    products = [
        {
            name: 'CABINETRY',
            description:
                'Our design specialists have an extensive selection to meet the demands of any project',
            image: './img/frame-83.png',
        },
        {
            name: 'DECKING',
            description:
                ' We feature quality decking, railing, and porch materials available in -stock and ready to deliver.',
            image: './img/frame-83-1.png',
        },
        {
            name: 'DOOR HARDWARE',
            description:
                '  Wide array of architectural hardware includes locks, hinges, doorknobs, and levers.',
            image: './img/frame-83-2.png',
        },
        {
            name: 'DOORS',
            description:
                '  Exterior and entry doors in a wide array of colors, styles, and brands.',
            image: './img/frame-83-3.png',
        },
    ],
    image = './img/careers.png',
}) {
    console.log({ header })
    return (
        <section className="services">
            <LandingImageWithHeader image={image} header="Products" />
            <div className="div-18">
                <div className="title-to-center">
                    <div className="text-wrapper-20">{header}</div>
                    <div className="rectangle" />
                </div>
                <div className="page-navigation">
                    <LeftArrow24Outline1
                        className="left-arrow"
                        color="#BEBEBE"
                    />{' '}
                    <LeftArrow24Outline4 className="left-arrow" />
                </div>
                <div className="services-scroll row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                    {products.map(({ image, name, description }) => (
                        <div className="col" key={name + image}>
                            <ProductCard
                                image={image}
                                header={name}
                                description={description}
                            />
                        </div>
                    ))}

                    {/* <LeftArrow24Outline4 className="left-arrow" /> */}
                </div>
            </div>
            <div className="view-all-wrapper">
                <div className="text-wrapper-24">VIEW ALL</div>
                <RightArrow24Outline2 className="right-arrow" color="#160AC2" />
            </div>
        </section>
    )
}
