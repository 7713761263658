import React from 'react'
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap'

export function ServicesCard({ image, header, description }) {
    return (
        <Card
            className="border-0 rounded"
            style={{
                width: '18rem',
            }}
        >
            <img className="rounded" alt="Sample" src={image} />
            <CardBody>
                <CardTitle tag="h5">Card title</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                    {header}
                </CardSubtitle>
                <CardText>{description}</CardText>
            </CardBody>
        </Card>
    )
}
