/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types'
import React from 'react'
import { Call24Outline1 } from '../../icons/Call24Outline1'

import { LocationIcon } from '../../icons/LocationIcon'
import './header.css'
import { Link, useNavigate } from 'react-router-dom'
import { HeaderNav } from './HeaderNav'

export const Header = ({
    address = '1211, Church Street Road, Chicago 560120',
    phoneNumber = '+01 9805269134',
    links = ['COMPANY', 'PRODUCTS', 'SERVICES', 'CAREERS'],
}) => {
    return (
        <>
            <div className={`bb-header sign-in component-4`}>
                <div className="frame-wrapper">
                    <div className="row banner">
                        <div className="frame-2 col-md-auto">
                            <p className="text-wrapper">
                                <LocationIcon
                                    className="instance-node"
                                    color="white"
                                />
                                {address}
                            </p>
                        </div>
                        <div className="frame-2 col-md-auto">
                            <p className="text-wrapper">
                                <Call24Outline1 className="instance-node" />
                                {phoneNumber}
                            </p>
                        </div>
                    </div>
                </div>
                <HeaderNav />
            </div>
        </>
    )
}

Header.propTypes = {
    headerType: PropTypes.oneOf(['sign-in', 'get-quote']),
}
