/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LeftArrow24Outline4 = ({ className }) => {
  return (
    <svg
      className={`left-arrow-24-outline-4 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.1833 21.1833C26.3351 21.0248 26.454 20.8379 26.5333 20.6333C26.7 20.2276 26.7 19.7724 26.5333 19.3667C26.454 19.1621 26.3351 18.9752 26.1833 18.8167L21.1833 13.8167C20.8695 13.5028 20.4438 13.3265 20 13.3265C19.5562 13.3265 19.1305 13.5028 18.8167 13.8167C18.5028 14.1305 18.3265 14.5562 18.3265 15C18.3265 15.4438 18.5028 15.8695 18.8167 16.1833L20.9833 18.3333H15C14.558 18.3333 14.134 18.5089 13.8215 18.8215C13.5089 19.134 13.3333 19.558 13.3333 20C13.3333 20.442 13.5089 20.8659 13.8215 21.1785C14.134 21.4911 14.558 21.6667 15 21.6667H20.9833L18.8167 23.8167C18.6604 23.9716 18.5365 24.1559 18.4518 24.359C18.3672 24.5621 18.3237 24.78 18.3237 25C18.3237 25.22 18.3672 25.4379 18.4518 25.641C18.5365 25.844 18.6604 26.0284 18.8167 26.1833C18.9716 26.3395 19.1559 26.4635 19.359 26.5481C19.5621 26.6328 19.78 26.6763 20 26.6763C20.22 26.6763 20.4379 26.6328 20.641 26.5481C20.844 26.4635 21.0284 26.3395 21.1833 26.1833L26.1833 21.1833ZM36.6667 20C36.6667 16.7036 35.6892 13.4813 33.8578 10.7405C32.0265 7.99967 29.4235 5.86345 26.378 4.60199C23.3326 3.34053 19.9815 3.01048 16.7485 3.65356C13.5155 4.29665 10.5458 5.884 8.21488 8.21488C5.884 10.5458 4.29665 13.5155 3.65356 16.7485C3.01048 19.9815 3.34053 23.3326 4.60199 26.378C5.86345 29.4235 7.99967 32.0265 10.7405 33.8578C13.4813 35.6892 16.7036 36.6667 20 36.6667C22.1887 36.6667 24.356 36.2356 26.378 35.398C28.4001 34.5604 30.2375 33.3327 31.7851 31.7851C34.9107 28.6595 36.6667 24.4203 36.6667 20ZM6.66665 20C6.66665 17.3629 7.44864 14.785 8.91373 12.5924C10.3788 10.3997 12.4612 8.69076 14.8975 7.68159C17.3339 6.67242 20.0148 6.40838 22.6012 6.92285C25.1876 7.43732 27.5634 8.7072 29.4281 10.5719C31.2928 12.4366 32.5627 14.8124 33.0771 17.3988C33.5916 19.9852 33.3276 22.6661 32.3184 25.1024C31.3092 27.5388 29.6002 29.6212 27.4076 31.0863C25.2149 32.5513 22.6371 33.3333 20 33.3333C16.4638 33.3333 13.0724 31.9286 10.5719 29.4281C8.07141 26.9276 6.66665 23.5362 6.66665 20Z"
        fill="#090088"
      />
    </svg>
  );
};
